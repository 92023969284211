import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'

import AxiosRequest from "../utils/axios-request"
import StarRating from 'vue-star-rating'

Vue.use TurbolinksAdapter

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show-rating'
  return false unless el?
  new Vue {
    el
    data: -> {
      rating_avg: undefined
      items: []
    }
    components: {
      StarRating
    }
    mounted: ->
      id = document.getElementById('id').dataset.id
      idcustomer = document.getElementById('idcustomer').dataset.idcustomer
      query = $.get "/comunity/#{idcustomer}/ratings/#{id}.json"
      query.done (res) =>
        {
          rating_avg
          items
        } = res
        @items = res.items
        @rating_avg = res.rating_avg
  }
